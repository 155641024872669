.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.custom-radio-checkbox .ant-checkbox-inner {
  display: none; /* Hide the checkmark */
}

.custom-radio-checkbox .ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #1890ff; /* Change border color on focus */
}

.custom-radio-checkbox .ant-checkbox-wrapper {
  display: inline-block;
  width: 16px; /* Adjust the width to make it look like a circle */
  height: 16px; /* Adjust the height to make it look like a circle */
  border: 1px solid #1890ff; /* Add a border to the circle */
  border-radius: 50%; /* Make the checkbox circular */
}

.ant-input-disabled {
  color: #000 !important;
}

.ant-spin-dot {
  font-size: 50px !important;
}

.ant-spin-dot-item {
  background-color: #024102 !important;
  height: 20px !important;
  width: 20px !important;
}

.ant-collapse-header {
  align-items: center !important;
}

.ant-modal-footer {
  text-align: center !important;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.navlink:hover,
.navlink-active {
  display: block;
  height: 40px;
  padding-top: 19px;
  cursor: pointer;
  border-left: solid 4px #0044FF;
  background-color: #CACACA;
  color: #0044FF;
  align-items: center;
}

.navlink {
  display: block;
  height: 40px;
  padding-top: 19px;
  cursor: pointer;
  align-items: center;
}

@media screen and (min-width: 800px) {
  .layout {
    min-height: 100vh;
    max-width: 100vw !important;
    margin-left: auto;
    margin-right: auto;
    background-color: #FFF;
  }

  .topbar,
  .bottom-navbar {
    width: 100% !important;
  }

  .mobile-only {
    display: none;
  }
}

@media screen and (min-width: 1210px) {
  .bottom-navbar {
    display: none !important;
  }
}

@media screen and (max-width: 800px) {
  .layout {
    min-height: 100vh;
    max-width: 100vw !important;
    margin-left: auto;
    margin-right: auto;
    background-color: #FFF;
  }

  .bottom-navbar {
    width: 100% !important;
  }

  .topbar {
    width: 100% !important;
  }

  .desktop-only {
    display: none;
  }
}

@media screen and (max-width: 360px) {
  .topbar {
    width: 100% !important;
  }
}

.navmenu {
  color: #FFF;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  margin: 20px;
  padding-bottom: 10px;
}

.navmenu:hover,
.navmenu.active {
  border-bottom: solid 2px #FFC727;
}

.nav-login {
  cursor: pointer;
  padding: 20px 10px 0px;
  width: 40%;
  height: 60px;
  font-weight: 500;
  border-bottom: solid 2px #757575;
  color: #757575;
  text-align: center;
  border-radius: 15px 15px 0px 0px;
}

.nav-login:hover,
.nav-login.active {
  border-bottom: solid 2px #E98A15;
  color: #E98A15;
  background-color: #F6D0A1;
}

.button-primary {
  background-color: #024012;
  color: #FFF;
  width: 100%;
  height: 60px;
  padding: 10px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-weight: bold;
}

.button-primary:hover {
  background-color: #4E7A4E;
}

.button-secondary {
  background-color: #FFF;
  color: #024012;
  width: 100%;
  height: 60px;
  padding: 10px;
  border: 1px solid #024012;
  border-radius: 10px;
  cursor: pointer;
  font-weight: bold;
}

.button-secondary:hover {
  background-color: #FAFAFA;
}

.button-danger {
  background-color: #FFF;
  color: #FF0000;
  width: 100%;
  height: 40px;
  padding: 10px;
  border: solid 2px #FF0000;
  border-radius: 10px;
  cursor: pointer;
  font-weight: bold;
}

.button-danger:hover {
  background-color: #FAFAFA;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
  width: 50%;
}

.topbar {
  z-index: 20;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  gap: 10px;

  position: fixed;
  height: 108px;

  /* Primary/50 */

  background: #024102;
}

.topbar.detail {
  justify-content: flex-start;
  background-image: "images/Top-bar.svg";

  color: #FFF;
  font-size: 18px;
  font-weight: 500;
}

.bottom-navbar {
  z-index: 2;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;

  /* font-weight: 500; */
  text-align: center;
  font-size: 0.8rem;

  position: fixed;
  height: 80px;
  bottom: 0;

  background: #FFFFFF;
  box-shadow: 0px -6px 10px rgba(0, 0, 0, 0.06);
  border-radius: 40px 40px 0px 0px;
}

.badge {
  justify-content: center;
  padding: 12px 16px;
  gap: 10px;

  max-width: 122px;
  height: 30px;

  font-size: 16px;
  font-weight: 700;

  color: #FFF;

  border-radius: 100px;
  flex: none;
  flex-grow: 0;
}

.badge.tk {
  background: #9747FF;
}

.badge.sd {
  background: #C52421;
}

.badge.smp {
  background: #3C5BFF;
}

.badge.skor {
  background: #F6D0A1;
  color: #E98A15;
  margin: auto!important;
  height: 100%!important;
}

.badge.medium {
  background: #F6D0A1;
  color: #E98A15;
  height: 30px;
  text-align: center;
}

.badge.easy {
  background: #beffdd;
  color: #0ABC5D;
  height: 30px;
  text-align: center;
}

.badge.hard {
  background: #fca4a4;
  color: #ff0000;
  height: 30px;
  text-align: center;
}

.card-kelas {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 22px;
  margin: 10px;
  gap: 10px;
  cursor: pointer;

  min-width: 90px;
  /* height: 48px; */

  text-align: center;
  font-size: 16px;
  font-weight: bold;
  color: #9E9E9E;

  background: rgba(224, 224, 224, 0.4);
  border-radius: 6px;
}

.card-kelas:hover,
.card-kelas.active {
  color: #FFF;
  background: #024102;
}

.card-mapel {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 24px 30px;
  gap: 30px;

  background: #FFFFFF;
  box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.08);
  border-radius: 15px;
}

.card {
  background: #FFFFFF;
  box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.08);
  border-radius: 15px;
}

.elipsis {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.learning-type {
  font-weight: 500;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: solid 1px #EDEDED;
}

.learning-type:hover {
  background-color: #FAFAFA;
}

.achievement:hover {
  background-color: #FAFAFA;
}

.pdfViewer {
  height: 100vh;
  width: 100vh;
}

.image-subject {
  width: 70px;
  height: 70px;
}

.image-subject:hover {
  filter: drop-shadow(10);
}